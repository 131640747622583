import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Define your translations
const resources = {
    en: {
        translation: {
            Home: "Home",
            Login: "Login",
            Register: "Register",
            Username: "Username",
            Password: "Password",
            Dashboard: "Dashboard",

            Balance: "Balance",
            Weapons: "Weapons",
            Armory: "Armory",
            Bank: "Bank",
            Buildings: "Buildings",
            Drugs: "Drugs",
            Gangs: "Gangs",
            Profile: "Profile",
            Messages: "Messages",
            Chat: "Chat",
            Contact: "Contact",
            Logout: "Logout",
            Robbery: "Robbery",
            accept_rules: "I read the rules and im accepting",
            accept_policy: "I read the policy and im accepting",
            Requirements: "Requirements",
            Stamina_Cost: "Stamina Cost",
            Required_Members: "Members Required",
            Attack: "Attack",
            VictimUsername: "Victim Username",
            Player_Stats: "Player Stats",
            Respect: "Respect",
            Strength: "Strength",
            Intelligence: "Intelligence",
            Charisma: "Charisma",
            Level: "Level",
            XP: "XP",
            Money: "Money",
            Stamina: "Stamina",
            Gang: "Gang",
            Weapon: "Weapon",
            Armor: "Armor",
            robbery_title: "Robbery Time",
            robbery_description: "What is money? Money equals power.<br />Even your own mom will hate you if you're broke.<br />And don't get me started on this ruthless world,<br />because it will crush you if you don't have money.<br /><br />So, stop making excuses and get some green. Make a few boxes and go on.<br /><br />Better risk equals better money. Yes, there is risk.<br />But, \"no risk no story.\"",
            small_heist: "Small Heist",
            bank_heist: "Bank Heist",
            casino_heist: "Casino Heist",
            //robbery_button: "Go For It",
            Go_For_It: "Go For It",
            robbery_description2: "Are you ready to make your move and take what's yours?",
            WeaponsDesc: "Get bigger and stronger!<br />If you have contain more weapons, you will become more stronger.",
            WeaponsDesc2: "User can buy multiple weapons OR this feature will be removed",
            Bat: "Bat",
            Dagger: "Dagger",
            mmBaretta: "14mm Baretta",
            uzi: "Uzi",
            ak47: "AK47",
            MachineGun: "Machine Gun",
            Buy: "Buy",
            Sell: "Sell",
            NoItems: "No Items",
            MyItems: "My Items",
            ArmoryDesc: "Get bigger and stronger!<br />If you have contain more armors, you will become more durable.<br />",
            ArmoryDesc2: "User can buy multiple armors OR this feature will be removed",
            KevlarVest: "Kevler Vest",
            SteelShield: "Steel Shield",
            BulletproofHelmet: "Bulletproof Helmet",
            ArmoredCar: "Armored Car",
            BankDesc1: "When you get jumped, the attacker will not leave your pockets alone. He will grab some money from your pocket before he runs away.",
            BankDesc2: "But nobody can reach your money in the bank. And there will be interest:",
            BankInterest1: "For 1,000,000 you will get 1,010,000 after 24 hours",
            BankInterest2: "For 5,000,000 you will get 5,500,000 after 24 hours",
            BankInterest3: "For 10,000,000 you will get 12,000,000 after 24 hours",
            BankPremiumNote: "For premium members, all interest will be doubled",
            BankBalance: "Bank Balance",
            WalletBalance: "Wallet Balance",
            Deposit: "Deposit",
            Withdraw: "Withdraw",
            BuildingsDesc: "Produce drugs on buildings!<br />If you are living underground this is your salary<br />",
            BuildingsDesc2: "Premium users produce more than casual users",
            SmallDrugLab: "Small Drug Lab",
            LargeDrugLab: "Large Drug Lab",
            WeaponFactory: "Weapon Factory",
            Warehouse: "Warehouse",
            Casino: "Casino",
            NightClub: "Night Club",
            YourBuildings: "Your Buildings",
            Quantity: "Quantity",
            Cost: "Cost",
            ProductionRate: "Production Rate",
            NoBuildings: "No Buildings",
            NoDrugs: "No Drugs",
            YourDrugs: "Your Drugs",
            SellGoodsDesc: "Sell your goods!<br />You have to gather from your buildings first.<br />",
            PrivateMessages: "Private Messages",
            Send: "Send",
            Inbox: "Inbox",
            Outbox: "Outbox",
            SendMessage: "Send Message",
            To: "To",
            Topic: "Topic",
            YourMessage: "Your Message",
            NoMessages: "No Messages",
            Sender: "Sender",
            Receiver: "Receiver",
            Message: "Message",
            Subject: "Subject",
            Close: "Close",
            Date: "Date",
            Time: "Time",
            ProfileTitle: "Profile",
            Premium: "Premium",
            Username: "Username",
            Kills: "Kills",
            CrimesCommitted: "Crimes Committed",
            Joined: "Joined",
            GangsTitle: "Gangs",
            YourGang: "Your Gang",
            GangName: "Gang Name",
            GangLeader: "Gang Leader",
            GangMembers: "Gang Members",
            Members: "Members",
            JoinRequests: "Join Requests",
            NoJoinRequests: "No Join Requests",
            Fire: "Fire",
            PromoteToCrew: "Promote To Crew",
            DegradeToMember: "Demote To Member",
            NoMembers: "No Members",
            QuitGang: "Quit Gang",
            Accept: "Accept",
            Decline: "Decline",
            NoJoinRequests: "No Join Requests",
            DisbandGang: "Disband Gang",
            AvailableGangs: "Available Gangs",
            Join: "Join",
            CreateGang: "Create Gang",


            title: "Gang Bangers",
            subtitle: "Join the most ruthless criminal underworld",
            intro: "Do you have what it takes to rise to the top?",
            intro_paragraph_1: "Join forces with your crew and take on the competition.",
            intro_paragraph_2: "Will you be the last one standing?",
            gameplay_paragraph_1: "Compete in intense turf wars and heists to earn cash and climb the ranks.",
            gameplay_paragraph_2: "Form alliances and make deals with other players to gain an edge.",
            gameplay_paragraph_3: "Build your criminal empire and control territories across the city.",
            registered_users: "Registered Users",
            join_button: "Join the Battle",
            prize_header: "💰 CASH PRIZES 💰",
            prize_announcement: "<div class='grid md:grid-cols-2 gap-4 text-xl'><div><p class='text-xl font-bold'>INDIVIDUAL WINNERS</p><p>🥇 1st Place: <span class='text-2xl'>$300</span></p><p>🥈 2nd Place: <span class='text-2xl'>$200</span></p><p>🥉 3rd Place: <span class='text-2xl'>$100</span></p></div><div><p class='text-xl font-bold'>FAMILY REWARDS</p><p>🥇 1st Place: <span class='text-2xl'>$200</span> per member</p><p>🥈 2nd Place: <span class='text-2xl'>$100</span> per member</p><p>🥉 3rd Place: <span class='text-2xl'>$50</span> per member</p></div></div>",
        





            Gang_Robbery: "Gang Robbery",
            GangRobberyTitle: "Gang Robbery",
            PlannedRobberyInfo: "Planned Robbery",
            AcceptedMembers: "Accepted Members",
            PlanRobbery: "Plan Robbery",
            AcceptRobbery: "Accept Robbery",
            ExecuteRobbery: "Execute Robbery",
            Loading: "Loading...",
            NoGangMessage: "You are not in a gang",
            RobberyPlannedSuccess: "Robbery planned successfully",
            RobberyAcceptedSuccess: "Robbery accepted successfully",
            ErrorPlanningRobbery: "Error planning robbery",
            ErrorAcceptingRobbery: "Error accepting robbery",
            ErrorExecutingRobbery: "Error executing robbery",
            ErrorLoadingData: "Error loading data",

            gang_small_heist: "Small Heist",
            gang_bank_heist: "Bank Heist",
            gang_casino_heist: "Casino Heist",

            FAQ: {
                header: "FAQ",
                what_is_xp: "What is XP?<br /> - XP stands for experience points...",
                how_to_increase_level: "How do I increase my level?<br /> - You can level up by...",
                use_of_money: "What is the use of money?<br /> - Money allows you to..."
            },

            TypeYourMessage: "Some words are sharper than knife...",

            CONTACT: {
                Contact_Text: "<b>GangBangers is a game made for players, by players!</b><br/>Got an idea? Is there something you dislike about the game? We're all ears!<br/>We are committed to giving you the best gaming experience possible. Your feedback matters.<br/>Every player’s request will be thoughtfully considered.<br/>",
                name: "Name:",
                name_placeholder: "Enter your name",
                email: "Email:",
                email_placeholder: "Enter your email",
                message: "Message:",
                message_placeholder: "Write your message",
                send_button: "Send"
            },
            // add other translations here
        }
    },
    tr: {
        translation: {
            Home: "Ana Sayfa",
            Login: "Giriş Yap",
            Register: "Kayıt Ol",
            Username: "Kullanıcı Adı",
            Password: "Şifre",
            Dashboard: "Genel",
            Balance: "Bakiye",
            Robbery: "Soygun",
            Weapons: "Silahlar",
            Armory: "Zırhlar",
            Bank: "Banka",
            Buildings: "Binalar",
            Drugs: "Mallar",
            Gangs: "Çeteler",
            Profile: "Profil",
            Messages: "Mesajlar",
            Chat: "Sohbet",
            Contact: "İletişim",
            Logout: "Çıkış",
            accept_rules: "Kuralları okudum ve kabul ediyorum",
            accept_policy: "Gizlilik Politikasını okudum ve kabul ediyorum",
            Requirements: "Gereklilikler",
            Stamina_Cost: "Stamina Gerekli",
            Required_Members: "Üyeler gerekli",
            Attack: "Saldırı",
            VictimUsername: "Kurbanın Kullanıcı Adı",
            Player_Stats: "Oyuncu Statistikleri",
            Respect: "Saygınlık",
            Strength: "Güç",
            Intelligence: "Zeka",
            Charisma: "Karizma",
            Level: "Seviye",
            XP: "XP",
            Money: "Para",
            Stamina: "Enerji",
            Gang: "Çete",
            Weapon: "Silah",
            Armor: "Zırh",
            robbery_title: "Soygun Zamanı",
            robbery_description: "Para nedir? Para güç demektir.<br />Fakirsen, annen bile senden nefret edecek.<br />Ve bu acımasız dünyadan bahsetmeme bile gerek yok,<br />çünkü paran yoksa seni ezip geçecek.<br /><br />Bu yüzden, bahaneleri bırak ve biraz paralan. Birkaç soygun yap ve gelişmeye devam et.<br /><br />Daha iyi risk, daha iyi para demektir. Evet, risk var.<br />Ama, \"risk yoksa hikaye de yok.\"",
            small_heist: "Küçük Heist",
            bank_heist: "Banka Heist",
            casino_heist: "Casino Heist",
            //robbery_button: "Vur!",
            Go_For_It: "Vur!",
            robbery_description2: "Senin olanı almaya hazır mısın ?",
            WeaponsDesc: "Güçlen ve büyü!<br />Eğer sana daha iyi silahların olursa, sen de daha güçlü olursun.",
            WeaponsDesc2: "Kullanıcılar birden fazla silah satın alabilir veya bu ozellik kaldırılacak",
            Bat: "Bat",
            Dagger: "Hançer",
            mmBaretta: "14mm Baretta",
            uzi: "Uzi",
            ak47: "AK47",
            MachineGun: "Makinali",
            Buy: "Satın Al",
            Sell: "Sat",
            NoItems: "Hicbir Oge Bulunamadi",
            MyItems: "Oğelerim",
            ArmoryDesc: "Daha saglam zirhlar daha saglam koruma!<br />Zirhin saglamsa postunu delmek kolay olmicak.<br />",
            ArmoryDesc2: "Kullanıcılar birden fazla zırh satın alabilir veya bu ozellik kaldırılacak",
            KevlarVest: "Kask",
            SteelShield: "Celik Kalkan",
            BulletproofHelmet: "KursunGecirmez Kask",
            ArmoredCar: "Zirhli Arac",
            BankDesc1: "Saldırıya uğradığınızda, saldırgan ceplerinizi boş bırakmayacak. Kaçmadan önce cebinizden biraz para alacak.",
            BankDesc2: "Ancak bankadaki paranıza kimse ulaşamaz. Ve faiz kazanacaksınız:",
            BankInterest1: "1.000.000 için 24 saat sonra 1.010.000 alacaksınız",
            BankInterest2: "5.000.000 için 24 saat sonra 5.500.000 alacaksınız",
            BankInterest3: "10.000.000 için 24 saat sonra 12.000.000 alacaksınız",
            BankPremiumNote: "Premium üyeler için tüm faiz oranları iki katına çıkarılacaktır",
            BankBalance: "Banka Bakiyesi",
            WalletBalance: "Cüzdan Bakiyesi",
            Deposit: "Yatır",
            Withdraw: "Çek",
            BuildingsDesc: "Binalarda uyuşturucu üret!<br />Yeraltında yaşıyorsan bu senin maaşın<br />",
            BuildingsDesc2: "Premium kullanıcılar, normal kullanıcılardan daha fazla üretir",
            SmallDrugLab: "Küçük Uyuşturucu Laboratuvarı",
            LargeDrugLab: "Büyük Uyuşturucu Laboratuvarı",
            WeaponFactory: "Silah Fabrikası",
            Warehouse: "Depo",
            Casino: "Kumarhane",
            NightClub: "Gece Kulübü",
            YourBuildings: "Sizin Binalarınız",
            Quantity: "Miktar",
            Cost: "Maliyet",
            ProductionRate: "Uretim Oranı",
            NoBuildings: "Hicbir Bina Yok",
            NoDrugs: "Hicbir Üretim Yok",
            YourDrugs: "Sizin Mallariniz",
            SellGoodsDesc: "Malları sat!<br />Önce binalarından toplaman gerekiyor.<br />",
            PrivateMessages: "Özel Mesajlar",
            Send: "Gönder",
            Inbox: "Gelen Kutusu",
            Outbox: "Giden Kutusu",
            SendMessage: "Mesaj Gönder",
            To: "Kime",
            Topic: "Konu",
            YourMessage: "Mesajınız",
            NoMessages: "Hicbir Mesaj Bulunamadi",
            Sender: "Gonderen",
            Receiver: "Alıcı",
            Message: "Mesaj",
            Subject: "Konu",
            Close: "Kapat",
            Date: "Tarih",
            Time: "Saat",
            ProfileTitle: "Profil",
            Premium: "Premium",
            Username: "Kullanıcı Adı",
            Kills: "Öldürme",
            CrimesCommitted: "Suçlar İşlendi",
            Joined: "Katıldı",
            GangsTitle: "Çeteler",
            YourGang: "Sizin Ceteniz",
            GangName: "Çetenin Adı",
            GangLeader: "Çetenin Lideri",
            GangMembers: "Çetenin Üyeleri",
            Members: "Uyeler",
            JoinRequests: "Katılma Istekleri",
            NoJoinRequests: "Hicbir Katılma Istegi Bulunamadi",
            Fire: "Kov",
            PromoteToCrew: "Ekibe Terfi Ettir",
            DegradeToMember: "Üyeye Düşür",
            NoMembers: "Hicbir Uye Bulunamadi",
            QuitGang: "Ceteden Cik",
            Accept: "Kabul Et",
            Decline: "Reddet",
            NoJoinRequests: "Hicbir Katilma Istegi Bulunamadi",
            DisbandGang: "Çeteyi İptal Et",
            AvailableGangs: "Mevcut Çeteler",
            Join: "Katıl",
            CreateGang: "Cete Olustur",

            title: "Gang Bangers",
            subtitle: "Yeraltının en acımasız dünyasına katılın",
            intro: "En tepeye çıkacak cesaretin var mı?",
            intro_paragraph_1: "Ekibinle güçlerini birleştir ve rakiplere karşı savaş.",
            intro_paragraph_2: "Son ayakta kalan sen mi olacaksın?",
            gameplay_paragraph_1: "Ödüller kazanmak ve sıralamalarda yükselmek için yoğun savaşlara ve görevlere katıl.",
            gameplay_paragraph_2: "Avantaj sağlamak için diğer oyuncularla ittifaklar kur ve anlaşmalar yap.",
            gameplay_paragraph_3: "Suç imparatorluğunu kur ve şehirde ki tüm bölgelerini yönet.",
            registered_users: "Kayıtlı Kullanıcılar: ",
            join_button: "Savaşa Katıl",
            prize_header: "💰 PARA ÖDÜLLERİ 💰",
            prize_announcement:
            "<div class='grid md:grid-cols-2 gap-4 text-xl'><div><p class='text-xl font-bold'>Tekli Kazananlar</p><p>🥇 1. Kişi: <span class='text-2xl'>$300</span></p><p>🥈 2. PlacKişi: <span class='text-2xl'>$200</span></p><p>🥉 3. Kişi: <span class='text-2xl'>$100</span></p></div><div><p class='text-xl font-bold'>Aile Ödülleri</p><p>🥇 1. Aile: <span class='text-2xl'>$200</span> üye başına</p><p>🥈 2. Aile: <span class='text-2xl'>$100</span> üye başına</p><p>🥉 3. Aile: <span class='text-2xl'>$50</span> kişi başına</p></div></div>",
            // add other translations here



            Gang_Robbery: "Çete Soygunu",
            GangRobberyTitle: "Çete Soygunu",
            PlannedRobberyInfo: "Planlanan Soygun",
            AcceptedMembers: "Kabul Eden Üyeler",
            PlanRobbery: "Soygun Planla",
            AcceptRobbery: "Soygunu Kabul Et",
            ExecuteRobbery: "Soygunu Gerçekleştir",
            Loading: "Yükleniyor...",
            NoGangMessage: "Bir çetede değilsin",
            RobberyPlannedSuccess: "Soygun başarıyla planlandı",
            RobberyAcceptedSuccess: "Soygun başarıyla kabul edildi",
            ErrorPlanningRobbery: "Soygun planlanırken hata oluştu",
            ErrorAcceptingRobbery: "Soygun kabul edilirken hata oluştu",
            ErrorExecutingRobbery: "Soygun gerçekleştirilirken hata oluştu",
            ErrorLoadingData: "Veri yüklenirken hata oluştu",

            gang_small_heist: "Küçük Soygun",
            gang_bank_heist: "Banka Soygunu",
            gang_casino_heist: "Casino Soygunu",

            FAQ: {
                header: "SSS",  // This will be the header for the FAQ section
                what_is_xp: "XP nedir?<br /> - XP, deneyim puanları anlamına gelir...",
                how_to_increase_level: "Seviyemi nasıl artırabilirim?<br /> - Seviye atlayabilirsiniz...",
                use_of_money: "Paranın kullanımı nedir?<br /> - Para size şunu sağlar..."
            },


            TypeYourMessage: "Bazı kelimeler bıçaktan daha keskindir...",

            CONTACT: {
                Contact_Text: "<b>GangBangers, oyuncular için yapılmış bir oyundur, oyuncular tarafından!</b><br/>Bir fikrin mi var? Oyunda beğenmediğin bir şey mi var? Her zaman dinliyoruz!<br/>Sana en iyi oyun deneyimini sunmak için buradayız. Geri bildirimin çok önemli.<br/>Her oyuncunun isteği dikkatlice değerlendirilecektir.<br/>",
                name_label: "Ad:",
                name_placeholder: "Adınızı girin",
                email_label: "E-posta:",
                email_placeholder: "E-posta adresinizi girin",
                message_label: "Mesaj:",
                message_placeholder: "Mesajınızı yazın",
                send_button: "Gönder"
            },

        }
    }
};

// Get the saved language from sessionStorage or fallback to 'en'
const savedLanguage = sessionStorage.getItem('language') || 'en';

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        //keySeparator: false, // FOR ENABLE MORE WORD WITH UNDERSCORE!!! ATTENTION
        // like for this robbery_button_1 .. otherwise it can onle word1_word2
        lng: savedLanguage, // set the default language
        fallbackLng: "en",
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

// Update sessionStorage whenever the language changes
i18n.on('languageChanged', (lng) => {
    sessionStorage.setItem('language', lng);
});

export default i18n;

