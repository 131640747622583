import React, { useEffect, useState } from 'react';
import { fetchMembersCount } from '../../services/usersService';
import useAuthStore from '../../store/authStore';
import { useTranslation } from 'react-i18next';
import '../../i18n/i18n';

function Home() {
  const { t } = useTranslation();
  const [registeredUsers, setRegisteredUsers] = useState([]);
  const { isAuthenticated, checkAuthStatus, loading } = useAuthStore();

  useEffect(() => {
    const initializePage = async () => {
      await checkAuthStatus();

      if (isAuthenticated) {
        return;
      } else {
        fetchUserCount();
      }
    };

    initializePage();
  }, [isAuthenticated, checkAuthStatus]);

  const fetchUserCount = async () => {
    try {
      // Attempt to fetch members count
      const data = await fetchMembersCount();
      setRegisteredUsers(data);
    } catch (error) {
      // Log error details, including response
      if (error.response) {
        console.error('Error fetching members count (response):', {
          status: error.response.status,
          data: error.response.data,
        });
      } else {
        console.error('Error fetching members count (network or other):', error.message);
      }
      // Optional: Set registeredUsers to an empty array to avoid UI issues
      setRegisteredUsers([]);
    }
  };

  // Show loading indicator while checking authentication status
  if (loading) {
    return <div>Loading...</div>;
  }

  // Display content for authenticated users
  if (isAuthenticated) {
    const kissSound = new Audio('/kiss2.wav');
    kissSound.volume = 0.3;
    setTimeout(() => {
      //kissSound.play();
    }, 8000);
    
    return (
      <div className="flex flex-col min-h-screen justify-center items-center">
        
      </div>
    );
  }
  
  // Display content for unauthenticated users
  return (
    <div className="flex flex-col min-h-screen">
      <div
        className="flex-grow bg-cover bg-center"
        style={{ 
          //backgroundImage: "url('/placeholder.svg?height=1080&width=1920')", 
          backgroundBlendMode: "overlay", 
          backgroundColor: "rgba(0,0,0,0.7)" 
        }}
      >
        {/* Prize announcement at the top */}
        <div className="bg-yellow-600 text-black font-black text-center p-4 rounded-lg mx-auto max-w-4xl my-8">
          <p className="text-2xl mb-2">{t('prize_header')}</p>
          <div dangerouslySetInnerHTML={{ __html: t('prize_announcement') }}></div>
        </div>

        <div className="container mx-auto p-4 pt-6 md:p-6 lg:p-12 xl:p-15 flex justify-center items-center">
          <div className="bg-black bg-opacity-80 rounded-lg p-8 text-center shadow-lg border border-yellow-600 max-w-3xl">
            <h1 className="text-5xl font-bold mb-4 text-yellow-600">{t('title')}</h1>
            <p className="text-2xl font-bold mb-4 text-yellow-600">{t('subtitle')}</p>
            <div className="text-lg leading-relaxed mb-4 text-gray-200">
              <p>{t('intro')}</p>
              <p>{t('intro_paragraph_1')}</p>
              <p>{t('intro_paragraph_2')}</p>
            </div>
            <div className="text-lg leading-relaxed mb-4 text-gray-200">
              <p>{t('gameplay_paragraph_1')}</p>
              <p>{t('gameplay_paragraph_2')}</p>
              <p>{t('gameplay_paragraph_3')}</p>
            </div>
            <div>
              {t('registered_users')}: {registeredUsers.length}
            </div>
            <br />
            <a
              className="bg-yellow-500 hover:bg-yellow-400 text-white font-bold py-2 px-4 rounded shadow-md"
              href="/register"
            >
              {t('join_button')}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
