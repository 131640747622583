import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useAuthStore from '../../store/authStore';
import useMessageStore from '../../store/privateMessageStore';
import { fetchInboxMessages, fetchUserProfile, markAsRead } from '../../services/privateMessageService';
import { initializeSSE, closeSSE } from '../../services/sseService';
import { FaUser, FaComments, FaMoneyBill, FaGun, FaBuilding, FaSyringe, FaUsers, FaEnvelope, FaRightFromBracket, FaMask } from 'react-icons/fa6';
import { GiNinjaMask, GiChestArmor } from 'react-icons/gi';
import { RiPoliceBadgeLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import '../../i18n/i18n';
import ReactCountryFlag from "react-country-flag"

const Header = () => {
    const { isAuthenticated, checkAuthStatus } = useAuthStore();
    const { username, setUsername, unreadCount, setUnreadCount, setInbox } = useMessageStore();
    const { t, i18n } = useTranslation();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [initialFetchComplete, setInitialFetchComplete] = useState(false);

    // Cache the unread count to avoid unnecessary updates
    const [cachedUnreadCount, setCachedUnreadCount] = useState(0);

    // Fetch messages after login
    useEffect(() => {
        if (isAuthenticated) {
            const fetchMessagesAfterLogin = async () => {
                try {
                    const fetchedUsername = await fetchUserProfile();
                    setUsername(fetchedUsername);

                    const inboxData = await fetchInboxMessages(fetchedUsername);
                    setInbox(inboxData);

                    const unreadUserMessages = inboxData.filter((message) => !message.read);
                    const unreadCount = unreadUserMessages.length;
                    setUnreadCount(unreadCount);
                    setCachedUnreadCount(unreadCount);
                } catch (error) {
                    console.error('Error fetching messages after login:', error);
                }
            };

            fetchMessagesAfterLogin();
        }
    }, [isAuthenticated, setUsername, setInbox, setUnreadCount]);

    // Initialize unread count on load
    useEffect(() => {
        const initializeUnreadCount = async () => {
            try {
                await checkAuthStatus();
                const fetchedUsername = await fetchUserProfile();
                setUsername(fetchedUsername);

                const inboxData = await fetchInboxMessages(fetchedUsername);
                setInbox(inboxData);

                const unreadUserMessages = inboxData.filter((message) => !message.read);
                const unreadCount = unreadUserMessages.length;
                setUnreadCount(unreadCount);
                setCachedUnreadCount(unreadCount);

                setInitialFetchComplete(true);
            } catch (error) {
                console.error('Error initializing unread messages:', error);
            }
        };

        if (!initialFetchComplete) {
            initializeUnreadCount();
        }
    }, [checkAuthStatus, setUsername, setInbox, setUnreadCount, initialFetchComplete]);

    // SSE for real-time updates
    useEffect(() => {
        if (isAuthenticated) {
            initializeSSE((data) => {
                if (data.type === 'new_user_message') {
                    const newCount = Math.max(0, data.count);
                    if (newCount !== cachedUnreadCount) {
                        setUnreadCount(newCount);
                        setCachedUnreadCount(newCount);
                    }
                }
            });

            return () => closeSSE(); // Cleanup on unmount
        }
    }, [isAuthenticated, cachedUnreadCount, setUnreadCount]);

    // Fallback polling for unread messages
    useEffect(() => {
        const intervalId = setInterval(async () => {
            if (username) {
                try {
                    const inboxData = await fetchInboxMessages(username);
                    const unreadMessages = inboxData.filter((message) => !message.read);
                    const unreadCount = unreadMessages.length;

                    if (unreadCount !== cachedUnreadCount) {
                        setUnreadCount(unreadCount);
                        setCachedUnreadCount(unreadCount);
                    }
                } catch (error) {
                    console.error('Error fetching fallback unread count:', error);
                }
            }
        }, 5000); // Every 5 seconds

        return () => clearInterval(intervalId); // Cleanup on unmount
    }, [username, cachedUnreadCount, setUnreadCount]);

    // Mark a message as read
    const handleMarkAsRead = async (messageId) => {
        try {
            await markAsRead(messageId);
            setUnreadCount((prevCount) => Math.max(prevCount - 1, 0));
        } catch (error) {
            console.error('Error marking message as read:', error);
        }
    };

    // Define languages with flags
    const languages = [
        { 
            code: 'en', 
            name: 'English',
            countryCode: 'GB'  // ISO 3166-1 country code
        },
        { 
            code: 'tr', 
            name: 'Türkçe',
            countryCode: 'TR'
        }
    ];


    // Change language and close dropdown
    const changeLanguage = (code) => {
        i18n.changeLanguage(code);
        setDropdownOpen(false);
    };

    return (
        <nav className="bg-black text-gray-200 py-4 shadow-lg border-b-4 border-black">
            <div className="container mx-auto flex justify-between items-center px-6">
                <div className="flex items-center space-x-8 mr-2">
                    <div className="relative mobile-dropdown mr-14">
                        <button
                            onClick={() => setDropdownOpen(!dropdownOpen)}
                            className="flex items-center text-2xl"
                        >
                            <ReactCountryFlag
                                countryCode={languages.find(lang => lang.code === i18n.language)?.countryCode}
                                svg
                                style={{
                                    width: '24px',
                                    height: '24px',
                                    marginRight: '8px'
                                }}
                                title={languages.find(lang => lang.code === i18n.language)?.name}
                            />
                            <span className="text-sm text-gray-200 font-bold">
                                {languages.find(lang => lang.code === i18n.language)?.name}
                            </span>
                        </button>

                        {dropdownOpen && (
                            <div className={`absolute right-0 mt-2 w-24 bg-white rounded shadow-lg z-50 dropdown-menu ${dropdownOpen ? '' : 'hidden'}`}>
                                {languages.map(({ code, countryCode, name }) => (
                                    <button
                                        key={code}
                                        onClick={() => changeLanguage(code)}
                                        className="flex items-center w-full px-4 py-2 text-gray-700 hover:bg-gray-100"
                                    >
                                        <ReactCountryFlag
                                            countryCode={countryCode}
                                            svg
                                            style={{
                                                width: '20px',
                                                height: '20px',
                                                marginRight: '8px'
                                            }}
                                            title={name}
                                        />
                                        <span className="text-sm">{name}</span>
                                    </button>
                                ))}
                            </div>
                        )}
                    </div>
                </div>

                {/* Desktop Navigation (only visible on larger screens) */}
                <div className="hidden md:flex space-x-4">
                    {!isAuthenticated ? (
                        <>
                            <Link to="/" className="hover:text-yellow-500 transition duration-300 text-lg font-bold tracking-wide">
                                <FaMask className="inline-block mr-2 text-yellow-500" /> {t('Home')}
                            </Link>
                            <Link to="/register" className="hover:text-yellow-500 transition duration-300 text-lg font-bold tracking-wide">
                                <FaUser className="inline-block mr-2 text-yellow-500" /> {t('Register')}
                            </Link>
                            <Link to="/login" className="hover:text-yellow-500 transition duration-300 text-lg font-bold tracking-wide">
                                <FaUser className="inline-block mr-2 text-yellow-500" /> {t('Login')}
                            </Link>
                        </>
                    ) : (
                        <>
                            <Link to="/robbery" className="hover:text-yellow-500 transition duration-300 text-lg font-bold tracking-wide">
                                <GiNinjaMask className="inline-block mr-2 text-yellow-500" /> {t('Robbery')}
                            </Link>   
                            <Link to="/gang_robbery" className="hover:text-yellow-500 transition duration-300 text-lg font-bold tracking-wide">
                                <GiNinjaMask className="inline-block mr-2 text-yellow-500" /> {t('Gang_Robbery')}
                            </Link>
                            <Link to="/attack" className="hover:text-yellow-500 transition duration-300 text-lg font-bold tracking-wide">
                                <RiPoliceBadgeLine className="inline-block mr-2 text-yellow-500" /> {t('Attack')}
                            </Link>
                            <Link to="/weapons" className="hover:text-yellow-500 transition duration-300 text-lg font-bold tracking-wide">
                                <FaGun className="inline-block mr-2 text-yellow-500" /> {t('Weapons')}
                            </Link>
                            <Link to="/armory" className="hover:text-yellow-500 transition duration-300 text-lg font-bold tracking-wide">
                                <GiChestArmor className="inline-block mr-2 text-yellow-500" /> {t('Armory')}
                            </Link>
                            <Link to="/bank" className="hover:text-yellow-500 transition duration-300 text-lg font-bold tracking-wide">
                                <FaMoneyBill className="inline-block mr-2 text-yellow-500" /> {t('Bank')}
                            </Link>
                            <Link to="/buildings" className="hover:text-yellow-500 transition duration-300 text-lg font-bold tracking-wide">
                                <FaBuilding className="inline-block mr-2 text-yellow-500" /> {t('Buildings')}
                            </Link>
                            <Link to="/drugs" className="hover:text-yellow-500 transition duration-300 text-lg font-bold tracking-wide">
                                <FaSyringe className="inline-block mr-2 text-yellow-500" /> {t('Drugs')}
                            </Link>
                            <Link to="/gangs" className="hover:text-yellow-500 transition duration-300 text-lg font-bold tracking-wide">
                                <FaUsers className="inline-block mr-2 text-yellow-500" /> {t('Gangs')}
                            </Link>

                            <Link to="/messages" className="relative hover:text-yellow-500 transition duration-300 text-lg font-bold tracking-wide">
                                <div className="relative inline-block">
                                    <FaEnvelope className="inline-block mr-2 text-yellow-500" />
                                    {unreadCount > 0 && (
                                        <span className="absolute top-0 -right-5 transform translate-x-1/2 -translate-y-1/2 bg-red-500 text-white rounded-full px-2 py-0.5 text-xs font-bold">
                                            {unreadCount}
                                        </span>
                                    )}
                                </div>
                                {t('Messages')}
                            </Link>


                            <Link to="/chat" className="hover:text-yellow-500 transition duration-300 text-lg font-bold tracking-wide">
                                <FaComments className="inline-block mr-2 text-yellow-500" /> {t('Chat')}
                            </Link>
                            <Link to="/contact" className="hover:text-yellow-500 transition duration-300 text-lg font-bold tracking-wide">
                                <RiPoliceBadgeLine className="inline-block mr-2 text-yellow-500" /> {t('Contact')}
                            </Link>
                            <Link to="/logout" className="hover:text-yellow-500 transition duration-300 text-lg font-bold tracking-wide">
                                <FaRightFromBracket className="inline-block mr-2 text-yellow-500" /> {t('Logout')}
                            </Link>
                        </>
                    )}
                </div>

                {/* Mobile Menu Button (only visible on smaller screens) */}
                <div className="md:hidden">
                    <button onClick={() => setMobileMenuOpen(!mobileMenuOpen)} className="text-white">
                        ☰
                    </button>
                </div>
            </div>

            {/* Mobile Navigation (visible only when mobileMenuOpen is true) */}
            {mobileMenuOpen && (
                <div className="md:hidden mt-4 mobile-dropdown">
                    {!isAuthenticated ? (
                        <>
                            <Link to="/" onClick={closeMobileMenu} className="block py-2 px-4 hover:bg-gray-700">
                                <FaMask className="inline-block mr-2 text-yellow-500" /> {t('Home')}
                            </Link>
                            <Link to="/register" onClick={closeMobileMenu} className="block py-2 px-4 hover:bg-gray-700">
                                <FaUser className="inline-block mr-2 text-yellow-500" /> {t('Register')}
                            </Link>
                            <Link to="/login" onClick={closeMobileMenu} className="block py-2 px-4 hover:bg-gray-700">
                                <FaUser className="inline-block mr-2 text-yellow-500" /> {t('Login')}
                            </Link>
                        </>
                    ) : (
                        <>
                            <Link to="/robbery" onClick={closeMobileMenu} className="block py-2 px-4 hover:bg-gray-700">
                                <GiNinjaMask className="inline-block mr-2 text-yellow-500" /> {t('Robbery')}
                            </Link>
                            <Link to="/gang_robbery" onClick={closeMobileMenu} className="block py-2 px-4 hover:bg-gray-700">
                                <GiNinjaHelmet className="inline-block mr-2 text-yellow-500" /> {t('Gang_Robbery')}
                            </Link>
                            <Link to="/weapons" onClick={closeMobileMenu} className="block py-2 px-4 hover:bg-gray-700">
                                <FaGun className="inline-block mr-2 text-yellow-500" /> {t('Weapons')}
                            </Link>
                            <Link to="/armory" onClick={closeMobileMenu} className="block py-2 px-4 hover:bg-gray-700">
                                <GiChestArmor className="inline-block mr-2 text-yellow-500" /> {t('Armory')}
                            </Link>
                            <Link to="/bank" onClick={closeMobileMenu} className="block py-2 px-4 hover:bg-gray-700">
                                <FaMoneyBill className="inline-block mr-2 text-yellow-500" /> {t('Bank')}
                            </Link>
                            <Link to="/buildings" onClick={closeMobileMenu} className="block py-2 px-4 hover:bg-gray-700">
                                <FaBuilding className="inline-block mr-2 text-yellow-500" /> {t('Buildings')}
                            </Link>
                            <Link to="/drugs" onClick={closeMobileMenu} className="block py-2 px-4 hover:bg-gray-700">
                                <FaSyringe className="inline-block mr-2 text-yellow-500" /> {t('Drugs')}
                            </Link>
                            <Link to="/gangs" onClick={closeMobileMenu} className="block py-2 px-4 hover:bg-gray-700">
                                <FaUsers className="inline-block mr-2 text-yellow-500" /> {t('Gangs')}
                            </Link>

                            <Link to="/messages" onClick={closeMobileMenu} className="relative hover:text-yellow-500 transition duration-300 text-lg font-bold tracking-wide">
                                {unreadCount > 0 && (
                                    <span className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-red-500 text-white rounded-full px-2 py-0.5 text-xs font-bold">
                                        {unreadCount}
                                    </span>
                                )}
                                <FaEnvelope className="inline-block mr-2 text-yellow-500" /> Messages
                            </Link>

                            <Link to="/chat" onClick={closeMobileMenu} className="block py-2 px-4 hover:bg-gray-700">
                                <FaEnvelope className="inline-block mr-2 text-yellow-500" /> {t('Chat')}
                            </Link>
                            <Link to="/contact" onClick={closeMobileMenu} className="block py-2 px-4 hover:bg-gray-700">
                                <RiPoliceBadgeLine className="inline-block mr-2 text-yellow-500" /> {t('Contact')}
                            </Link>
                            <Link to="/logout" onClick={closeMobileMenu} className="block py-2 px-4 hover:bg-gray-700">
                                <FaRightFromBracket className="inline-block mr-2 text-yellow-500" /> {t('Logout')}
                            </Link>
                        </>
                    )}
                </div>
            )}
        </nav>
    );
};

export default Header;
