import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/PageStructure/Layout';
import Register from './auth/Register';
import Login from './auth/Login';
import Logout from './auth/Logout';
import Home from './components/Pages/Home';
import TermsAndConditions from './components/Legal/TermsAndConditions';
import PrivacyPolicy from './components/Legal/PrivacyPolicy';
import Robbery from './components/Robbery';
import GangRobbery from './components/GangRobbery';
import Attack from './components/Attack/Attack';
import Weapons from './components/Weapons';
import Armory from './components/Armory';
import Bank from './components/Bank';
import ProtectedRoute from './components/ProtectedRoute';
import PublicRoute from './components/PublicRoute';
import Buildings from './components/Buildings';
import Drugs from './components/Drugs';
import Gangs from './components/Gang/Gangs';
import PrivateMessage from './components/PrivateMessage';
import Chat from './components/Pages/Chat';
import Contact from './components/Pages/Contact';
import NotFound from './components/Pages/404';
import CountdownDisplay from './components/Content/CountdownDisplay';

function App() {
  const [password, setPassword] = useState('');
  const [authenticated, setAuthenticated] = useState(false);

  // Check local storage for authentication state on component mount
  useEffect(() => {
    const isAuth = localStorage.getItem('isAuthenticated');
    if (isAuth === 'true') {
      setAuthenticated(true);
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (password === 'daynight') {
      setAuthenticated(true);
      localStorage.setItem('isAuthenticated', 'true'); // Save authentication state to local storage
    }
  };

  if (!authenticated) {
    return (
      <div>
        <center>
          <h1>Enter Password</h1>
          <form onSubmit={handleSubmit}>
            <input type="password" value={password} onChange={(event) => setPassword(event.target.value)}
              style={{
                border: '2px solid #000000',
              }}
              required /><br />
            <button type="submit">Submit</button>
          </form>
        </center>
      </div>
    );
  }

  return (
    <Router>
      <Routes>
        {/* Layout route that wraps all other routes */}
        <Route path="/" element={<Layout />}>
          {/* Public routes */}
          <Route index element={<Home />} />
          <Route path="register" element={<Register />} />
          <Route path="login" element={<Login />} />
          <Route path="contact" element={<Contact />} />
          <Route path="terms" element={<TermsAndConditions />} />
          <Route path="privacy" element={<PrivacyPolicy />} />

          {/* Protected routes */}
          <Route path="countdown" element={<ProtectedRoute><CountdownDisplay /></ProtectedRoute>} />
          <Route path="robbery" element={<ProtectedRoute><Robbery /></ProtectedRoute>} />
          <Route path="gang_robbery" element={<ProtectedRoute><GangRobbery /></ProtectedRoute>} />
          <Route path="attack" element={<ProtectedRoute><Attack /></ProtectedRoute>} />
          <Route path="weapons" element={<ProtectedRoute><Weapons /></ProtectedRoute>} />
          <Route path="armory" element={<ProtectedRoute><Armory /></ProtectedRoute>} />
          <Route path="bank" element={<ProtectedRoute><Bank /></ProtectedRoute>} />
          <Route path="buildings" element={<ProtectedRoute><Buildings /></ProtectedRoute>} />
          <Route path="drugs" element={<ProtectedRoute><Drugs /></ProtectedRoute>} />
          <Route path="gangs" element={<ProtectedRoute><Gangs /></ProtectedRoute>} />
          <Route path="messages" element={<ProtectedRoute><PrivateMessage /></ProtectedRoute>} />
          <Route path="chat" element={<ProtectedRoute><Chat /></ProtectedRoute>} />
          <Route path="logout" element={<ProtectedRoute><Logout /></ProtectedRoute>} />

          {/* Catch-all for 404 Not Found */}
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
