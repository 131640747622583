//frontend/src/services/authService.js
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:5000";

export const registerUser = async (formData) => {
    try {
        const response = await axios.post(`${API_URL}/auth/register`, formData, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response.data.message === 'User registered successfully!') {
            return response.data;
        } else if (response.data.message === 'User already exists') {
            throw new Error('User already exists');
        } else {
            throw new Error('Error registering user');
        }
    } catch (error) {
        throw error;
    }
};

export const loginUser = async (email, password) => {
    
    try {
        const response = await fetch(`${API_URL}/auth/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({ email, password }),
        });

        // Try to parse the response as JSON first
        let data;
        try {
            data = await response.json();  // Attempt to parse JSON response
        } catch (e) {
            console.warn("Failed to parse JSON response, trying as text...");
            data = await response.text();  // If JSON parsing fails, parse it as text
        }

        // If response is not OK (error status code), throw an error
        if (!response.ok) {
            //console.error('Login error response:', data);
            throw new Error(data.message || data || 'Login failed.');
        }

        return data;  // Return the parsed JSON data (or text if JSON failed)

    } catch (error) {
        //console.error('Error during login:', error);
        throw error;
    }
};

export const passwordReset = async (email) => {
    try {
        const response = await fetch(`${API_URL}/auth/password-reset`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Password reset failed.');
        }

        return await response.json();
    } catch (error) {
        throw error;
    }
};


export const logoutUser = async () => {
    try {
        const response = await fetch(`${API_URL}/auth/logout`, {
            method: 'POST',
            credentials: 'include',  // Include cookies in the request
        });

        if (!response.ok) {
            throw new Error('Failed to log out');
        }
    } catch (error) {
        console.error('Logout error:', error);
        throw error;
    }
};

