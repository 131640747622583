const API_URL = process.env.REACT_APP_API_URL || 'https://gangbangers.com.tr/api';

// Fetch recent messages
const fetchMessages = async () => {
    try {
        const response = await fetch(`${API_URL}/chat/messages`);
        const data = await response.json();

        if (!response.ok) {
            console.error('Error fetching messages:', data.message);
            return { success: false, message: 'Failed to fetch messages' };
        }

        return { success: true, messages: data.messages };
    } catch (error) {
        console.error('Unexpected error:', error);
        return { success: false, message: 'Internal server error' };
    }
};

// Save a new message
const sendMessage = async (username, message, user_id) => {
    if (!username || !message || !user_id) {
        return { success: false, message: 'Invalid message data' };
    }

    try {
        const response = await fetch(`${API_URL}/chat/messages`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, message, user_id }),
        });
        const data = await response.json();

        if (!response.ok) {
            console.error('Error saving message:', data.message);
            return { success: false, message: 'Failed to send message' };
        }

        return { success: true, message: data.message };
    } catch (error) {
        console.error('Unexpected error:', error);
        return { success: false, message: 'Internal server error' };
    }
};

export default { fetchMessages, sendMessage };