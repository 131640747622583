import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthStore from '../store/authStore';  // Import Zustand store
import { loginUser, passwordReset } from '../services/authService';  // Import the login API function
import { useTranslation } from 'react-i18next';
import '../i18n/i18n';

const Login = () => {
  const [responseMessage, setResponseMessage] = useState('');  // Store response messages
  const [loading, setLoading] = useState(false);  // Loading state for button disable
  const { setUser } = useAuthStore();  // Zustand auth store
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  const shotsFired = new Audio('shotsFired.mp3');

  const handleLogin = (email, password) => {
    setLoading(true);  // Show loading
    loginUser(email, password)
      .then(data => {
        if (data && data.user) {
          setResponseMessage('Login successful!');
          const { id, username } = data.user;
          setUser({ id, username });

          //shotsFired.play();
          navigate('/');

        } else {
          setResponseMessage(data?.message || 'Login failed.');
        }
      })
      .catch(error => {
        if (error.message === 'Invalid password' || error.message === 'User not found') {
          setResponseMessage('Wrong email or password');
        } else {
          setResponseMessage('An error occurred. Please try again.');
        }
      })
      .finally(() => {
        setLoading(false);  // Stop loading state
      });
  };

  const handlePasswordReset = () => {
    const email = document.querySelector('input[name="email"]').value;
    if (!email) {
      setResponseMessage('Please enter your email address');
      return;
    }
    
    passwordReset(email)
      .then(data => {
        setResponseMessage('Password reset link sent to your email.');
      })
      .catch(error => {
        setResponseMessage('An error occurred. Please try again.');
      });
  };

  return (
    <div className="min-h-[85vh] bg-cover bg-center flex justify-center items-center" style={{ backgroundImage: `url('./skull.jpg')`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <div className="bg-black bg-opacity-75 rounded-lg p-8 text-center shadow-lg w-96 border-2 border-yellow-500">
        <h2 className="text-3xl font-bold mb-4 text-yellow-500">{t('Login')}</h2>
        <form onSubmit={(e) => {
          e.preventDefault();
          const email = e.target.email.value;
          const password = e.target.password.value;
          handleLogin(email, password);
        }}>
          <div className="mb-2">
            <label className="block text-white text-sm font-bold" htmlFor="email">Email:</label>
            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline bg-transparent border-yellow-500" type="email" name="email" required />
          </div>
          <div className="mb-2">
            <label className="block text-white text-sm font-bold" htmlFor="password">{t('Password')}:</label>
            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline bg-transparent border-yellow-500" type="password" name="password" required />
          </div>
          <button type="submit" disabled={loading} className="bg-yellow-500 hover:bg-yellow-600 text-black font-bold py-2 px-4 rounded w-full">
            {loading ? 'Logging in...' : t('Login')}
          </button>
          <button type="button" onClick={handlePasswordReset} className="mt-2 text-yellow-500 hover:underline">
            {t('Forgot Password?')}
          </button>
        </form>
        <p className="text-yellow-500 mt-2">{responseMessage}</p>
      </div>
    </div>
  );

};

export default Login;

